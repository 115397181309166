'use strict';

(function() {
  class ViewFullImageModalCtrl {
    constructor($uibModalInstance, imageUrl) {
      this.$uibModalInstance = $uibModalInstance;
      this.imageUrl = imageUrl;
      // console.log('image url: ', imageUrl);
    }
  }
  angular.module('windmanagerApp')
    .controller('ViewFullImageModalCtrl', ViewFullImageModalCtrl);
})();
